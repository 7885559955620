<template>
  <div class="modal modal_wrap" >
    <div class="modal_popup modal_full modal_additional">
      <div class="container modal_content">
        <div class="row">
          <div class="col-md-12 col-sm-12">

            <div v-if="items.accessories">
              <h3 class="text_navy">Selected Accessories</h3>

              <div class="row item-list"
                   v-for="(item, index) in items.accessories">

                <div class="col-12 col-md-2">
                  <span>{{item.qty}}</span>
                </div>
                <div class="col-12 col-md-8">
                  {{item.name}} - <!--{{item.colour.name}}-->
                </div>
                <div class="col-12 col-md-1 text-right">
                  $ {{Number(item.price).toFixed(2)}}
                </div>
                <div class="col-12 col-md-1 text-right">
                  <span class="list__img" @click="editAdditionalAccessory(item, index)">
                    <svg-icon name="edit"  />
                  </span>
                  <span class="list__img" @click="openDelModal(item)">
                    <svg-icon name="times-circle" />
                  </span>
                </div>

              </div>
            </div>

            <p v-else class="text text_green text_italic">
              You have not created any accessory yet. Please click the button to create an accessory.
            </p>

            <div class="row justify-content-center">
              <div class="col-md-3 col-sm-4 col-6">
                <div class="thumbnailsTable">
                    <span class="thumbnailsTable__img" @click="selectNew">
                      <svg-icon name="plus-circle" class="large_icon"/>
                    </span>
                  <span class="thumbnailsTable__text" @click="selectNew">Create New Accessory</span>
                </div>
              </div>
            </div>

            <transition name="fade">
              <delete-modal
                @delete="openDelModal"
                v-if="show"
                :additionalAccessory="additionalAccessory"
                :deleteAccessory="deleteAccessory"
              />
            </transition>

            <additional-accessories v-if="window.openAdditionalWindow"
                                    :orderId="orderId"
                                    :selectIt="selectIt"
                                    :styleId="styleId"
            />
          </div>
        </div>
      </div>
      <button class="btn btn_transparent" @click="createAddAccessories" v-if="items.locations && items.locations.Accessories"><svg-icon name="solid/check" /> Done</button>
      <button class="btn btn_transparent" @click="cancelCreateAdditionals" v-else><svg-icon name="solid/times" /> Cancel</button>
    </div>
  </div>
</template>

<script>

  import router from "../../router";
  import DeleteModal from '@/components/modals/DeleteModal';
  import AdditionalAccessories from './additionalAccessory/AdditionalAccessories';

  import {mapState, mapGetters} from 'vuex';

  export default {
    name: 'SelectAccessories',
    components: {
      DeleteModal, AdditionalAccessories
    },

    data() {
      return {
        userName: '',
        show: false,
        selectAccessory: false,
        additionalAccessory: true,
        selectItem: false,
        deleteAccessory: {
          id: '',
          itemId: '',
        },
        orderId: '',
        selectIt: false,
        styleId: ''
      };
    },

    methods: {
      selectNew() {
        this.$emit('newAccessor');
        this.$store.commit('accessories/windowOpen', 'openAdditionalWindow');
        this.orderId = this.items.order_id;
      },

      openDelModal(item) {
        this.deleteAccessory.id = this.items.order_id;
        this.deleteAccessory.itemId = item.item_id;
        this.show = true;
        this.$store.commit('createdOrder/deleteModal', true);
      },

      editAdditionalAccessory(item) {
        this.$store.commit('accessories/updateAddAccessoriesItem', item);
        this.$store.commit('orderPreview/editAccessory', true);
        this.$store.commit('accessories/windowOpen', 'openAdditionalWindow');
        this.$store.commit('accessories/windowOpen', 'openAdditionalSize');
        this.selectIt = true;
        this.styleId = item.main_item_id;
      },

      showAccessories() {
        if (!this.items.locations.Accessories)
          return;
        let itemsObj = {};
        if (this.items.locations.Accessories.items.standard) {
          itemsObj = this.items.locations.Accessories.items.standard;
        }
        else if (this.items.locations.Accessories.items.custom) {
          itemsObj = this.items.locations.Accessories.items.custom;
        }
        return _.filter(itemsObj, function (item) {
          if (item.type_name !== "LBR") return item;
        });
      },

      createAddAccessories() {
        this.$store.commit('createdOrder/additionalAccessories', false);
      },

      cancelCreateAdditionals() {
        this.$store.commit('createdOrder/additionalAccessories', false);
      },

    },

    computed: {
      ...mapGetters({
        editAccessory: 'orderPreview/editAccessory',
        items: 'orderPreview/items',
      }),
      ...mapState('accessories', ['window']),
      getUserName() {
        return localStorage.getItem('nameUser');
      },
    },

  };
</script>

<style scoped lang="scss">

  @import "../../../src/assets/scss/utils/vars";

  .form {
    border: 1px solid $navy;
    padding: 20px 20px 4px 20px;
    margin-bottom: 2rem;
    position: relative;
    &__input {
      border: none;
      border-bottom: 2px solid $navy;
      padding-right: 160px;
      padding-left: 0;
    }
    &__label {
      margin: 0;
    }

    .btn {
      position: absolute;
      top: 20px;
      right: 20px;
      color: $green;
      font-family: $bold;
      font-size: $h5;
      text-decoration: none;
      margin: 0;
      padding-right: 0;
    }

    .ui.search.selection.dropdown > input.search {
      padding: 0 !important;
    }
  }

  .ui.selection.dropdown {
    border: none;
    padding: 0 30px 0 0;
  }

  .ui.search.dropdown {
    & > input.search {
      border-bottom: 2px solid $navy !important;
      display: none !important;
      padding: 0;
    }

    .text {
      padding: 0 10px !important;
    }
  }

  @media screen and (max-width: $sm) {
    .form {
      &__input {
        padding-right: 130px;
      }
    }
  }

  .list__accessories {

    max-height: 350px;
    overflow: auto;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .thumbnailsTable{
    padding: 0;
    margin: 20px 0;
    cursor: pointer;
  }

  .item-list{
    padding: 10px 0px;
  }

  .item-list:nth-child(even){
    background-color: #DDD;
  }

  .action{
    display: inline-block;
  }

  .text-right{
    text-align: right;
  }



</style>
