<template>
  <Modal :isOpen="true" @onClose="closePopup" @onBack="moveBackNew"  :showBackButton="step !== steps.TYPE">
    <h2 class="titlePage_subtitle titlePage">Accessories</h2>

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="d-flex flex-column nav-links pb-4">
          <button @click="step = steps.TYPE" v-if="step >= steps.TYPE" class="btn btn_link" :class="{'active': step === steps.TYPE}">
            TYPE:
            <span class="d-block text_small ml-2">{{currentItemTypeStyle.typeDescription}} - {{ currentItemTypeStyle.styleDescription }}</span>
          </button>
          <button @click="step = steps.ITEM" v-if="step >= steps.ITEM" class="btn btn_link" :class="{'active': step === steps.ITEM}">
            ITEM:
            <span class="d-block text_small ml-2">{{additionalAccessory.name}}</span>
          </button>
          <button @click="step = steps.SIZE" v-if="step >= steps.SIZE" class="btn btn_link" :class="{'active': step === steps.SIZE}">
            SPECIFICATION:
          </button>
        </div>
      </div>
      <div class="col-sm-12 col-md-9 pb-4">
        <transition name="fade">
          <accessories-type :accessory="accessories"
                            v-if="step === steps.TYPE"
                            @selectType="selectTypeFrom"
          ></accessories-type>

          <accessories-item :accessories-items="accessoriesItems"
                            v-else-if="step === steps.ITEM"
                            @selectItem="selectItemFrom"
          ></accessories-item>

          <additional-accessories-size v-else actionLinksPortalTarget="additional-accessories-footer-left"
          ></additional-accessories-size>

          <!--<accessories-color :colors="this.colors"-->
          <!--v-if="selectColor"-->
          <!--@createAdditional="addAdditionalAccessory"-->
          <!--@update="updateAccessory"-->
          <!--@close="closeUpdate"-->
          <!--&gt;</accessories-color>-->
        </transition>
      </div>
    </div>
    <template v-slot:footer>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <portal-target name="additional-accessories-footer-left">
          </portal-target>
        </div>
        <div></div>
      </div>
    </template>
  </Modal>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import {mapState, mapGetters} from 'vuex';
  const AccessoriesType = defineAsyncComponent(() => import('./AccessoriesType'));
  const AccessoriesItem = defineAsyncComponent(() => import('./AccessoriesItem'));
  const AdditionalAccessoriesSize = defineAsyncComponent(() => import('./AdditionalAccessoriesSize'));
  const Modal = defineAsyncComponent(() => import('@/components/modals/Generic'))


  export default {
    name: 'AdditionalAccessories',
    data() {
      return {
        steps: {
          TYPE: 0,
          ITEM: 1,
          SIZE: 2,
        },
        step: 0,
        dataModel: {},
        index: '',
        selectType: true,
        selectStyle: false,
        selectItem: false,
        selectColor: false,
        order: '',
        additionalAccessories: true,
        accessory: {
          typeName: '',
          name: '',
          type: '',
          style: '',
          width: '',
          grade: '',
          drop: '',
          item: this.styleId || '',
          mitreCut: null,
          lengthVal: {},
          measureType: '',
        }
      }
    },

    components: {AccessoriesItem, AccessoriesType, AdditionalAccessoriesSize, Modal},

    props: ['orderId', 'selectIt', 'styleId'],

    computed: {
      ...mapGetters({
        additionalAccessory: 'orderPreview/additionalAccessory',
        accessories: 'accessories/accessories',
        accessoriesItems: 'accessories/accessoriesItems',
        currentItemTypeStyle: 'accessories/currentItemTypeStyle',
      }),
      ...mapState('accessories', ['window']),
    },

    watch: {
      accessories: {
        handler: function (val, oldVal) {
          if(_.isEmpty(oldVal) && !_.isEmpty(val) && this.additionalAccessory.mode === 'edit') {
            const { typeId, styleId } = this.currentItemTypeStyle
            this.$store.dispatch('accessories/getStyle', {item: styleId, type: typeId});
          }
        },
        immediate: true,
      },
    },

    methods: {
      moveBackNew() {
        const backLinks = {
          [this.steps.SIZE]: this.steps.ITEM,
          [this.steps.ITEM]: this.steps.TYPE,
        }
        this.step = backLinks[this.step] || this.steps.TYPE
      },
      moveBack() {
        if(this.step === this.steps.ITEM) {
          this.step = this.steps.TYPE
          return this.$store.commit('orderPreview/additionalAccessory', {style: 0});
        }
        if(this.step === this.steps.SIZE) {
          this.step = this.steps.ITEM
          return this.$store.commit('orderPreview/additionalAccessory', {item: 0, qty: 1, length1: 0, length2: 0, length3: 0, length4: 0});
        }
      },

      selectTypeFrom(item, type) {
        this.step = this.steps.ITEM;
        this.$store.commit('orderPreview/additionalAccessory', {style: item.id});
        this.$store.dispatch('accessories/getStyle', {item: item.id, type: type.id});
      },

      selectItemFrom(item) {
        this.step = this.steps.SIZE;
        this.accessory.measure_type = item.measure_type;
        this.$store.commit('orderPreview/additionalAccessory', {item: item.id});
          this.$store.commit('accessories/setAdditionalAccessory', item);
      },

      addAdditionalAccessory() {
        this.$store.commit('createdOrder/updateOrderId', this.order);
        this.$store.commit('createdOrder/updateStyle', this.accessory.style);
        this.$store.commit('createdOrder/updateType', this.accessory.type);
        this.$store.commit('createdOrder/updateGrade', this.accessory.grade);
        this.$store.commit('createdOrder/createAccessory', this.accessory);
        this.$store.dispatch('createdOrder/putUpdatesAdditionalAccessory', this.$store.getters['createdOrder/createdOrder']);
        this.closePopup();
      },

      updateAccessory() {
        this.$store.dispatch('orderItem/updateAdditionalAccessory', this.accessory);
        this.closePopup();
      },

      closePopup() {
        this.$store.commit('orderPreview/additionalAccessory', {open: false});
      },

      closeUpdate() {
        this.select = true;
        this.selectType = false;
        this.selectItem = false;
        this.selectColor = false;
        this.$parent.selectIt = false;
        this.$parent.selectAccessory = false;
        this.$store.commit('orderPreview/editAccessory', false);
        this.$store.commit('accessories/windowClose', 'openAdditionalWindow');
      }
    },

    created() {
      this.$store.dispatch('accessories/getAccessories');
    },

    mounted() {
      if(this.additionalAccessory.mode === 'edit' ) {
        this.step = this.steps.SIZE
      }
    },

    beforeDestroy(){
      this.$store.commit('orderPreview/cleanAccessory', false);
      this.$store.dispatch('accessories/cleanup');
    }
  }

</script>
<style scoped lang="scss">
  @import "../../../assets/scss/utils/vars";
  .modal__close {
    color: $green;
    top: 0.7rem;
  }
  .modal_additional .titlePage {
    color: $navy;
  }

  .nav-links {
    .btn {
      margin-top: 0;
      margin-bottom: 0;
      border-radius: 0.42rem;
      color: $gray;
      text-decoration: none;
      text-align: left;
      &:hover {
        color: $navy;
      }
      &.active {
        color: $navy;
        background-color:  rgba($green, .3)
      }
      &:disabled {
        color: $lightGray;
      }
    }
  }
</style>
