<template>
  <div class="modal modal_wrap" v-if="deleteModal" @keyup.esc="closeDelete">
    <div class="modal_popup">
      <p class="text text_white" v-if="package">
        Are you sure you want to delete this package?
        <span class="modal__close" @click="closeDelete">
         <svg-icon name="times"/>
        </span>
      </p>
      <p class="text text_white" v-else>
        Are you sure you want to delete this accessory?
        <span class="modal__close" @click="closeDelete">
         <svg-icon name="times"/>
        </span>
      </p>
      <div class="justify-content-between">
        <button class="btn btn_transparent" @click="closeDelete"><svg-icon name="solid/step-backward" /> Back</button>
        <button class="btn btn_transparent" @click="delPackages"><svg-icon name="solid/trash-xmark" /> Delete</button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "DeleteModal",

    data() {
      return {}
    },

    props: ['id', 'package', 'additionalAccessory', 'deleteAccessory'],

    methods: {
      closeDelete() {
        this.$store.commit('createdOrder/deleteModal', false);
      },

      delPackages() {

        if (this.additionalAccessory) {
            this.$emit('delete', this.id);
          // this.$store.dispatch('orderItem/delete', this.deleteAccessory);
        }
        else {
          this.$emit('delete', this.id);
          this.$store.commit('createdOrder/deleteModal', false);

          if (this.package === true) {
            this.$store.dispatch('packages/delPackage', this.id);
          }

          // if (!this.package && this.additionalAccessory === undefined) {
          //   this.$store.commit('createdOrder/delAccessory', this.id);
          //   this.$store.dispatch('accessories/updateOrderPriceInOrder');
          // }
        }

        setTimeout(() => {
          this.$store.dispatch('packages/getPackages');
          this.$store.dispatch('sets/getSets');
        }, 500);
      },
    },

    computed: {
      deleteModal() {
        return this.$store.getters['createdOrder/deleteModal'];
      },
    }
  }
</script>

<style scoped>
  .justify-content-between {
    display: flex;
  }
</style>
